import { Checkbox } from "antd"
import { CheckboxChangeEvent } from "antd/lib/checkbox"
import Col from "antd/lib/col"
import Divider from "antd/lib/divider"
import Row from "antd/lib/row"
import { find, first } from "lodash"
import { useEffect, useState } from "react"
import { connect, useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { getNextDoctor } from "client/optic/selectors"
import { SOCKET_ENGINE_DIAGNOSTIC_START } from "client/socket/constants"
import { HARDWARE_LUNEAU_TYPE } from "client/socket/optic/contants"
import { Button } from "components/Button"
import { Buttons, Card } from "components/Card/Card"
import PreviousButton from "components/Previous/PreviousButton"
import Consent from "containers/Consent/Consent"
import { PaymentCard } from "containers/Stripe"
import actionsSurvey from "containers/Survey/services/actions"
import { getCurrentPatientNir } from "containers/Survey/services/selector"
import { Answer } from "containers/Survey/types"
import { cdn } from "core/cdn"
import { CALL_PAGE, SURVEY_PAGE } from "core/constants"
import withSurveyController from "hocs/withSurveyController"
import { languages } from "lib/languages"
import { PATH } from "models/survey/_paths"
import { AvailableDoctor, DoctorType } from "types/entity"
import { Diagnostic } from "types/payload"
import { Dispatcher, Message } from "types/redux"
import { PaymentStore } from "types/store"
import { SocketStore } from "types/store"

import { logTerminalInformation } from "../../client/socket/actions"
import styles from "./Payment.module.scss"
import actions from "./services/actions"

const enum PaymentState {
  INITIALIZE = "INITIALIZE",
  EXEMPTED = "EXEMPTED",
  PAYMENT = "PAYMENT",
}

const Price = (props) => {
  if (!props.orthoptist || !props.ophtalmologist) return <></>

  return (
    <div className={styles.Prices}>
      <div
        className={`${styles.PricesContainer} ${props.hardwareType !== HARDWARE_LUNEAU_TYPE && styles.ForNidekTimeline
          }`}
      >
        <div className={styles.PriceOphtalmologist}>
          Tarif : <b>{props.ophtalmologist.price}€</b>
        </div>
      </div>
    </div>
  )
}
const Payment = (props: PaymentStore & { diagnostic: Diagnostic }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [paymentState, setPaymentState] = useState(PaymentState.INITIALIZE)
  const [exempted, setExempted] = useState(false)
  const [currentPrice, setCurrentPrice] = useState(null)
  const nextDoctor: DoctorType | false = useSelector(getNextDoctor)
  const hardwareType = useSelector(
    ({ socket }: { socket: SocketStore }) => socket.hardwareType
  )
  const [consentVisibility, setConsentVisibility] = useState(false)
  const answers = useSelector(({ survey }) => survey.answers)
  const [consentChoice, setConsentChoice] = useState<boolean>(false)
  //lancement du diagnostic des machines
  useEffect(() => {
    if (nextDoctor === DoctorType.ORTHOPTIST && props.success === true) {
      dispatch({ type: SOCKET_ENGINE_DIAGNOSTIC_START })
    }
  }, [props.success])

  useEffect(() => {
    if (nextDoctor === DoctorType.ORTHOPTIST) {
      setExempted(true)
      setPaymentState(PaymentState.EXEMPTED)
    }
  }, [nextDoctor, props, paymentState])

  useEffect(() => {
    // Retrieve terminal information and send them to API
    if (props.success) {
      dispatch(logTerminalInformation())
    }
  }, [props.success])

  const getDoctorTimeline = (doctor: "orthoptist" | "ophtalmologist") => {
    return hardwareType === HARDWARE_LUNEAU_TYPE
      ? `/images/optic/timeline/frise_step_${doctor}.svg`
      : `/images/optic/timeline/nidek/frise_step_${doctor}.svg`
  }

  useEffect(() => {
    if (nextDoctor === DoctorType.ORTHOPTIST) {
      setExempted(true)
      setPaymentState(PaymentState.EXEMPTED)
    } else if (!answers) {
      // Nous n'avons pas accès aux réponses du questionnaire
      // Le patient ne peux pas lancer de TLC
      navigate(SURVEY_PAGE)
    } else {
      // Nous vérifions l'état de son exonération
      // Par défaut, false, puis nous mettons à jour l'état de la page
      // exmption ? PaymentState.EXEMPTED : PaymentState.PAYMENT
      const exempted = answers.find(
        (answer: Answer) =>
          answer.questionId === PATH.exemption &&
          Array.isArray(answer.value) &&
          first(answer.value) === true
      )
      setExempted(exempted)
    }
  }, [answers])
  // Réception du prix
  useEffect(() => {
    const relative = find(
      answers,
      (answer) => answer.questionId === PATH.relative
    )
    if (!props.price)
      props.getPrice({ relativeId: first(relative?.value) as string })
    else {
      const p =
        nextDoctor === DoctorType.ORTHOPTIST
          ? props.price.orthoptist
          : props.price.ophtalmologist
      setCurrentPrice(p)
    }
  }, [props.price])

  useEffect(() => {
    if (props.success) {
      navigate(CALL_PAGE)
      props.reset()
    }
  }, [props.success])

  switch (paymentState) {
    case PaymentState.PAYMENT:
    default:
      return (
        <Card
          titleSize="small"
          title={languages.youWillLaunchATLC}
          message={props.message}
        >
          <PreviousButton
            onClick={() => {
              setPaymentState(PaymentState.INITIALIZE)
            }}
            to={SURVEY_PAGE}
          />
          <div className={styles.PaymentInformation}>
            <Row align="middle" justify="center" gutter={[10, 10]}>
              <Col span={4}>
                <img src={cdn("/icons/picto_money.svg")} alt="" />
              </Col>
              <Col span={20}>
                {props.price ? (
                  nextDoctor === DoctorType.ORTHOPTIST ? (
                    <span className={styles.PaymentInformationText}>
                      Tarif : Jusqu'à <b>{props.price?.orthoptist?.price} €</b>{" "}
                      selon le bilan visuel
                      <br />
                      <i>{props.price?.orthoptist?.content}</i>
                    </span>
                  ) : (
                    <span className={styles.PaymentInformationText}>
                      Tarif : <b>{props.price?.ophtalmologist?.price} €</b>
                      <br />
                      <i>{props.price?.ophtalmologist?.content}</i>
                    </span>
                  )
                ) : (
                  <span className={styles.PaymentInformationText}>
                    Tarif en cours...
                  </span>
                )}
              </Col>
              <Col span={4}>
                <img src={cdn("/icons/picto_information.svg")} alt="" />
              </Col>
              <Col span={20}>
                <span className={styles.PaymentInformationText}>
                  {languages.chargeAtTheEnd}
                </span>
              </Col>
            </Row>
          </div>
          <Divider />
          <PaymentCard
            onPay={(pay: Answer[]) => {
              const send = [...answers, ...pay]
              props.tlcRequest(send)
            }}
          />
        </Card>
      )
    case PaymentState.INITIALIZE:
    case PaymentState.EXEMPTED:
      const config =
        nextDoctor === DoctorType.ORTHOPTIST
          ? {
            title: `Prochaine étape : mise en relation avec l'orthoptiste`,
            imgUrl: getDoctorTimeline("orthoptist"),
          }
          : {
            title: `Prochaine étape : mise en relation avec l'ophtalmologue`,
            imgUrl: getDoctorTimeline("ophtalmologist"),
          }
      return (
        <Card
          title={config.title}
          // subtitle={languages.nextMeansYouAcceptCGU}
          imgSize="medium"
          imgUrl={cdn(config.imgUrl)}
          imgPosition="bottom"
          message={props.message}
        >
          <PreviousButton
            onClick={() => {
              props.reset()
              dispatch(actionsSurvey.previous())
            }}
            to={SURVEY_PAGE}
          />
          {exempted ? (
            <div
              style={{
                maxWidth: "1375px",
                margin: "auto",
                marginTop: "-50px",
                marginBottom: "50px",
              }}
            >
              <Checkbox
                checked={consentChoice}
                onChange={(event: CheckboxChangeEvent) => {
                  setConsentChoice(event.target.checked)
                }}
              >
                {languages.IConfirmmyConsentDataChoice}
                <span
                  className="more-information"
                  onClick={(ev) => {
                    ev.preventDefault()
                    setConsentVisibility(true)
                  }}
                >
                  En savoir plus...
                </span>
              </Checkbox>
            </div>
          ) : null}
          {exempted ? null : (
            <Price
              hardwareType={hardwareType}
              nextDoctor={nextDoctor}
              ophtalmologist={props.price?.ophtalmologist}
              orthoptist={props.price?.orthoptist}
            />
          )}
          {nextDoctor === DoctorType.OPHTALMOLOGIST ? (
            <div className={styles.Infos}>
              <img src={cdn("/icons/information.svg")} width="30px" alt="information" />
              <span>
                {languages.additionalFeeCanBeCharged}
              </span>
            </div>
          ) : null}
          <Buttons style={{ paddingTop: 0 }}>
            <Button
              type="primary"
              disabled={(exempted && !consentChoice) || props.loading}
              loading={props.loading}
              onClick={() => {
                exempted && props.tlcRequest(answers)
                !exempted && setPaymentState(PaymentState.PAYMENT)
              }}
            >
              {exempted ? languages.createCall : languages.next}
            </Button>
          </Buttons>
          <Consent
            visible={consentVisibility}
            onCancel={() => {
              setConsentVisibility(false)
            }}
            onAccept={() => {
              setConsentChoice(true)
              setConsentVisibility(false)
            }}
          ></Consent>
        </Card>
      )
  }
}

const mapDispatchToProps = (dispatch: Dispatcher): Partial<PaymentStore> => {
  return {
    getPrice: ({ relativeId }: { relativeId?: string }) =>
      dispatch(actions.priceRequest({ relativeId })),
    tlcRequest: (a: Answer[]) => dispatch(actions.submit(a)),
    reset: () => dispatch(actions.reset()),
  }
}
// Give the store inside the props
export default connect(
  ({
    payment,
    stripe,
  }: {
    payment: PaymentStore
    stripe: { message?: Message }
  }) => {
    return {
      ...payment,
      message: payment.message ?? stripe.message,
    }
  },
  mapDispatchToProps
)(withSurveyController(Payment, { hideNext: true, hideTitle: true }))
