import { Col, Row } from "antd"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useNavigate } from "react-router-dom"

import { Button } from "components/Button"
import FatButton from "components/Button/FatButton/FatButton"
import { Buttons, Card } from "components/Card/Card"
import { Carousel } from "components/Carousel/Index"
import { Input } from "components/Input"
import { Modal } from "components/Modal/Modal"
import PreviousButton from "components/Previous/PreviousButton"
import { Important, Information } from "components/Title/Title"
import surveyActions from "containers/Survey/services/actions"
import { Question, Reply } from "containers/Survey/types"
import { SURVEY_PAGE } from "core/constants"
import { Keyboard } from "hocs/withKeyboard"
import { languages } from "lib/languages"
import { PATH } from "models/survey/_paths"
import { AvailableDoctor } from "types/entity"
import { Dispatcher } from "types/redux"
import { DoctorsStore, SurveyStore } from "types/store"

import styles from "./Doctors.module.scss"
import actions from "./services/actions"
import { AnalyzeNameFromRequest, getFilteredDoctorsByRequest } from "./utils"

import DOMPurify from "dompurify";

type DoctorsProps = DoctorsStore & { unauthorized: boolean }

const Doctor: React.FC<
  AvailableDoctor & { request?: string; onClick: (a: AvailableDoctor) => void }
> = ({ onClick, request, ...props }): JSX.Element => {
  return (
    <FatButton onClick={() => onClick(props)}>
      <span className={styles.DisplayDoctor}>
        <div className={styles.WrappedName}>
          <AnalyzeNameFromRequest
            request={request}
            name={`${props.firstname} ${props.lastname}`}
          />
        </div>
        <div className={styles.WrappedAddress}>{props.clinic_address}</div>
        <div
          className={props.is_available ? styles.Available : styles.Unavailable}
        >
          {props.is_available ? "Disponible" : "Indisponible"}
        </div>
      </span>
    </FatButton>
  )
}

const Doctors: React.FC<DoctorsProps> = (props): JSX.Element => {
  const [filteredDoctors, setFilteredDoctors] = useState<AvailableDoctor[]>([])
  const [request, setRequest] = useState<string>("")
  const [chosenDoctor, setChosenDoctor] = useState<AvailableDoctor | undefined>(
    undefined
  )
  useEffect(() => {
    props.getDoctors()
  }, [])
  const navigate = useNavigate()

  const surveyPushResponse = () => {
    // comme toujours, vider la réponse existante
    props.unset()
    // Si le docteur est disponible, on le pousse dans la réponse de la question courante
    if (chosenDoctor?.is_available) {
      props.set([
        {
          title: "chosen-doctor",
          value: chosenDoctor as AvailableDoctor,
        },
      ])
    }
    // On reset le Store Doctor
    props.reset()
    // on passe à la prochaine question,
    // On revient à la page Survey
    props.next()
    navigate(SURVEY_PAGE)
  }

  const modalTexts =
    chosenDoctor && chosenDoctor?.is_available
      ? {
        title: `Dr ${chosenDoctor.firstname} ${chosenDoctor.lastname}`,
        content: (
          <div>
            <div>
              <div className={styles.WrappedAddress}>
                {chosenDoctor.clinic_address}
              </div>
              <span className={styles.Available}>
                {languages.availability} : {languages.now}
              </span>
            </div>
          </div>
        ),
        acceptText: languages.IConsultThisDoctor,
      }
      : {
        title: "",
        content: (
          <h1
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(languages.thisDoctorIsNotAvailable_html, { USE_PROFILES: { html: true } }),
            }}
          />
        ),
        acceptText: languages.continue,
      }

  useEffect(() => {
    if (props.unauthorized) navigate(SURVEY_PAGE)
  }, [props.unauthorized])

  return (
    <Card title={languages.chooseADoctor} style={{ maxWidth: "2000px" }}>
      <PreviousButton
        onClick={() => {
          props.unset()
          navigate(SURVEY_PAGE)
        }}
      />
      <Row>
        <Col span={10}>
          <Keyboard
            options={{
              type: "alpha",
              inputName: "request",
            }}
            value={request}
            onChange={(value) => {
              setRequest(value)
              if (value.length < 3) setFilteredDoctors([])
              else if (props.doctors)
                setFilteredDoctors(
                  getFilteredDoctorsByRequest(props.doctors, value)
                )
            }}
          >
            <Input
              placeholder={languages.requestDoctorPlaceholder}
              style={{ marginBottom: "50px" }}
              value={request}
              name="request"
            />
          </Keyboard>
        </Col>
        <Col span={14} style={{ height: "560px" }}>
          {!filteredDoctors.length && request.length >= 3 ? (
            <Buttons direction="vertical">
              <Important text={`${languages.doctorNotInList} ?`} />
              <Button
                onClick={() => {
                  surveyPushResponse()
                }}
              >
                {languages.consultNow}
              </Button>
            </Buttons>
          ) : (
            <Carousel
              itemsPerPage={4}
              items={filteredDoctors.map((doctor, key) => (
                <Doctor
                  key={key}
                  {...doctor}
                  onClick={setChosenDoctor}
                  request={request}
                />
              ))}
              infinite={false}
            />
          )}
        </Col>
      </Row>
      <Buttons style={{ paddingTop: "0" }}>
        <Information text={languages.ifDoctorDoNotExist} />
      </Buttons>
      <Modal
        visible={!!chosenDoctor}
        closable={true}
        title={modalTexts.title}
        onClose={() => setChosenDoctor(undefined)}
        acceptText={modalTexts.acceptText}
        acceptButtonProps={{
          type: "primary",
        }}
        onAccept={() => {
          surveyPushResponse()
        }}
      >
        <div className={styles.DisplayDoctor}>{modalTexts.content}</div>
      </Modal>
    </Card>
  )
}

const p = (dispatch: Dispatcher): Partial<DoctorsStore> => {
  return {
    getDoctors: () => dispatch(actions.get()),
    reset: () => dispatch(actions.reset()),
    previous: () => dispatch(surveyActions.previous()),
    remove: (path: PATH) => dispatch(surveyActions.remove(path)),
    create: (payload: {
      question: Question
      options?: { after?: PATH; before?: PATH }
    }) => dispatch(surveyActions.create(payload)),
    next: () => dispatch(surveyActions.next()),
    set: (replies: Reply[]) =>
      dispatch(surveyActions.setStepReplies({ replies })),
    unset: (unReplies?: Reply[]) =>
      dispatch(surveyActions.unsetStepReplies({ replies: unReplies })),
    resetSurvey: () => dispatch(surveyActions.reset()),
  } as Partial<DoctorsStore>
}

const s = ({
  survey,
  doctors,
}: {
  doctors: DoctorsStore
  survey: SurveyStore
}) => {
  return {
    ...doctors,
    unauthorized: survey.current?.id !== PATH.choice_of_doctor,
  }
}
export default connect(s, p)(Doctors)
