/* eslint-disable no-unused-vars */

export enum ERROR_READER_SOCKET {
  API_LEC_OPEN_ERROR = 'API_LEC_OPEN_ERROR',
  API_LEC_INIT_ERROR = 'API_LEC_INIT_ERROR',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  PCSC_READER_ERROR = 'PCSC_READER_ERROR',
  SOFT_CARD_READ_ERROR = 'SOFT_CARD_READ_ERROR',
  MUTED_OR_UNKNOWN_CARD_ERROR = 'MUTED_OR_UNKNOWN_CARD_ERROR',
  GENERAL_ERROR = 'GENERAL_ERROR',
}

export enum ERROR_API {
  SUCCESS = 200,
  MULTIPLE_CHOICE = 300,
  PAYLOAD_ERROR = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  TIMEOUT_ERROR = 408,
  CONFLICT = 409,
  TOO_MANY_CALLS_FOR_A_CUSTOMER = 427,
  TOO_MANY_REQUEST = 429,
  EXPIRED_TOKEN = 433,
  MISMATCHING_INFORMATION = 434,
  NEXMO_ERROR = 435,
  PHONE_EXISTING_ERROR = 436,
  STRIPE = 438,
  EXPIRED_TOKEN_PHARMACY = 439,
  DELETED_INSTALLATION = 440,
  EXISTING_ORDER = 458,
  MAINTENANCE = 503
}

export interface PayloadError {
  code: ERROR_API
  error: typeof ERROR_API
  details: unknown
  status: string
}
export interface ResponseError {
  code : ERROR_API,
  message : string
}
