import LocalizedStrings, { GlobalStrings, LocalizedStringsMethods } from "react-localization"

import { cdn } from "core/cdn"
import { DoctorType } from "types/entity"

export type IStrings = LocalizedStringsMethods & {
  [key: string]: any
}

export const languages: IStrings = new LocalizedStrings({
  fr: {
    birthLocation_with_insee_code: "Lieu / INSEE de naissance",
    birth_names: "Prénom/Nom de naissance",
    pseudo_names: "Prénom/Nom utilisé",
    accessibility_declaration: "Déclaration d'accessibilité",
    chargeAtTheEnd: "Vous serez facturé à la fin de la consultation",
    twoFa_choseMethodForResend:
      "Choisissez une méthode pour recevoir à nouveau le code de vérification.",
    twoFa_enterRecievedCode: "Entrez le code de vérification reçu",
    twoFa_enterValideCode: "Veuillez entrer un code valide.",
    twoFa_resendCode: "RENVOYER LE CODE",
    twoFa_codeHasBeenResentBy: (transport) => `Le code a été renvoyé par ${transport}`,
    twoFa_Titre: "Code de vérification",
    twoFa_invalidCode: "Le code de vérification est incorrect",
    twoFa_activationError:
      "Une erreur est survenue lors de l'activation du 2FA sur votre compte.",
    twoFa_ResendError:
      "Une erreur est survenue lors du renvoi du code de vérification.",
    twoFa_activateTitle:
      "Protégez votre compte en activant la double authentification (2FA)",
    twoFa_activateSubtitle:
      "Pour vous connecter à votre compte, en plus de votre mot de passe, vous recevrez un code à 6 chiffres.",
    diagnosis_authorization_on_going: "Autorisation en cours",
    retry: "Réessayer",
    relaunchTest: `RELANCER LE TEST`,
    pharmacistsPlease: "Opticiens, merci de bien vouloir :",
    authorizeAccess: `Autoriser l’accès au micro et/ou à la caméra, puis relancer le test.`,
    disconnectAndReconnect: `Débrancher et rebrancher le câble de la caméra, puis relancer le test.`,
    ifYouNeedHelp: `En cas de besoin, vous pouvez contacter le support au`,
    diagnosis_information_unknown_error:
      "Le micro et/ou la caméra ne semblent pas fonctionner. Merci de vous rapprocher du référent de la borne.",
    diagnosis_title_unknown_error: "Problèmes techniques détectés",
    diagnosis_contact_support:
      "Opticien, contactez le support en cas de besoin :",
    diagnosis_information_permission_denied:
      "Le micro et/ou la caméra ne semblent pas être autorisés par le navigateur. Merci de vous rapprocher du référent de la borne.",
    diagnosis_title_permission_denied:
      "La caméra et/ou le micro ne sont pas autorisés",
    diagnosis_information_waiting_device:
      "Vous devez autoriser le micro et la caméra.",
    diagnosis_title_waiting_device: "Demande d'autorisation en cours...",
    diagnosis_title_success: "Récupération des flux vidéos et audios",
    genericAPIError: "Une erreur est survenue.",
    code_insee: "Code INSEE de naissance",
    relativeConsent:
      "L'ajout d'un proche sur MEDADOM necéssite son consentement",
    noCreditCardAdded: "Vous n'avez aucune carte d'enregistrée",
    noTLC: "vous n'avez pas de téléconsultation",
    yourCreditCards: "Vos cartes",
    information_from_carte_vitale: "Voici les informations récupérées de votre carte vitale :",
    subtitleCallAcceptedAndSoon:
      "est en consultation, vous êtes le prochain patient.",
    waitingDoctor: "Merci de patienter...",
    tagline: "Solution de téléophtalmologie",
    andYour: "Et votre",
    and: "Et",
    stayLogged: "Rester connecté",
    genericErrorPaymentCard:
      "Une erreur est survenue lors de l'ajout de votre moyen de paiement",
    setupIntentErrorStripe: "Impossible d'ajouter la carte.",
    genericErrorStripe:
      "Une erreur est survenue. Impossible de lancer la requête.",
    beSureToBeDisconnected: "Êtes-vous sûr(e) de vouloir vous déconnecter ?",
    finishAndDisconnect: "Terminer et déconnexion",
    myAdministratorAccount: "Mon compte administrateur",
    launchFullVideoTuto: "Lancer la formation complète",
    launchChapterVideoTuto: "Parcourir les vidéos de formation",
    videoManual: "Vidéos d'utilisation de la borne",
    close: "Fermer",
    sex: "Sexe :",
    confirmTheSendingOfYourDocuments:
      "Confirmez-vous l'envoi de vos documents à l'opticien* ?",
    myStatistics: "Mes statistiques",
    myTutorialVideos: "Vidéos de formation",
    modal_pharmaAccount_title: "Espace professionnel",
    modal_pharmaAccount_information:
      "En cas de question, vous pouvez contacter le support: <br /> <span>Du <b>lundi au samedi </b>de <b>9h à 19h30</b></span> <br /> <span>au <b>01 88 24 22 87</b></span> <br/><span>à l'adresse <b>contact@medadom.com</b></span>",
    select_reason: "Selectionnez un motif ...",
    select_birth_location: "Lieu de naissance",
    anamneseReasonQuestion: "Quel est le motif de votre consultation ?",
    anamneseReasonQuestion_relative_male:
      "Quel est le motif de consultation de votre proche ?",
    anamneseReasonQuestion_relative_female:
      "Quel est le motif de consultation de votre proche ?",
    birthCountry: "pays de naissance",
    birthLocation: "Lieu de naissance",
    createCardSuccess: "Votre carte a été ajoutée",
    createCardError: "Votre carte n'a pas pu être ajouté",
    city: "ville",
    covidHTML: "Coronavirus<br />(Covid 19)",
    covid: "Coronavirus (Covid 19)",
    allergy: "Allergies",
    burn: "Brûlure",
    contraception: "Contraception",
    cystitis: "Cystite",
    jointpain: "Douleurs articulaires (genou, poignet)",
    jointpainWithoutParenthesis: "Douleurs articulaires",
    diarrhea_or_vomiting: "Diarrhée ou vomissement",
    toothpain: "Douleurs dentaires",
    back_pain: "Douleurs lombaires",
    rash: "Éruption cutanée",
    tired: "Fatigue / anxiété / stress",
    fever: "Fièvre",
    sexual_infection: "Infection sexuelle",
    sore_throat: "Maux de gorge",
    headache: "Maux de tête",
    stomach_ache: "Maux de ventre",
    eye_pain: "Œil rouge et/ou douleur",

    sting: "Piqûre / morsure / blessure",
    otitis: "Oreilles/otite",
    prescription_refill: "Renouvellement d'ordonnance",
    period_pain: "Règles douloureuses",
    lab_results: "Résultats de laboratoire",
    cold_and_flu: "Rhume et syndrome grippal",
    sinus: "Sinus ou nez bouché",
    cough: "Toux",
    otherReason: "Autre",
    DMLA: "DMLA",
    DoYouHaveANir: "Avez-vous une carte vitale ?",
    IHaveAccount: "J'ai déjà <b style='font-weight:normal'>un compte</b>",
    IHaveNoAccount: "Je n'ai pas <b style='font-weight:normal'>de compte</b>",
    IHaveNoEmail: "J'atteste ne pas avoir d'adresse e-mail",
    IHaveNoNir: "Je n'ai pas de carte vitale",
    IHaveNir: "J'ai une carte vitale",
    RelativeHasNir: "Le proche a une carte vitale",
    TooManyRequest: "Trop de requêtes. Veuillez réessayer ultérieurement",
    unauthorized: "utilisateur non autorisé",
    WhenWasThelastConsultation:
      "A quand remonte votre dernière consultation (physique) en cabinet ophtalmologique ?",
    aPatientLogged: "Un patient est en cours d'utilisation : ",
    ame: "Aide Médicale d'État",
    c2s: "Couverture Maladie Universelle",
    ald: "Affection Longue Durée",
    indicateYourRights: "Merci d'indiquer vos droits :",
    indicateRelativeRights: "Merci d'indiquer les droits de votre proche :",
    rightsAttest:
      "j'atteste sur l'honneur que je bénéficie de la C2S (CMU ou AME) ou de l'ALD. Dans le cas contraire, je m'expose à des poursuites.",
    rightsAttestRelative:
      "j'atteste sur l'honneur que mon proche bénéficie de la C2S (CMU ou AME) ou de l'ALD. Dans le cas contraire, je m'expose à des poursuites.",
    ameModalTitle: "Attestation sur l'honneur",
    fraudIsPunished: "Toute fraude à l'Assurance Maladie est punie",
    ameModalAccept: "J'atteste sur l'honneur la validité",
    ameModalCancel: "Je ne bénéficie pas",
    ameModalCancelRelative: "Mon proche ne bénéficie pas",
    estimatedTime: "Attente estimée",
    yourPaymentMethod: "Votre moyen de paiement",
    youWillPayOnlyAfterConsultation:
      "Vous ne serez facturé qu'à la fin de la téléconsultation",
    ameRelativeBenefit: "Le proche bénéficie de la CMU, AME, ALD",
    WeAutomaticallyDetectedBenefit:
      "La carte vitale nous indique votre appartenance CMU, AME, ALD, 100%",
    ameIBenefit: "Je bénéficie de la CMU, AME, ALD",
    ameModalContent: `<table class="table-modal">
        <tr>
          <td>
            <img src="${cdn("icons/carte_vitale_picto.svg")}">
          </td>
          <td>
            <span class="modal-content">
            <b>Si vous êtes bénéficiaire de la C2S (CMU ou AME) ou ALD, vous bénéficiez d'une exonération de frais.</b>
            Pour cela, vous devez impérativement avoir une attestation à jour de votre Couverture Maladie Universelle, de votre Aide Médicale d'État, ou de votre Affection Longue Durée.
          </span>
            </td>
        </tr>
        <tr>
          <td>
            <img src="${cdn("icons/pharmacien_picto.svg")}">
          </td>
          <td>
            <span class="modal-content">
            Celle-ci pourra être vérifiée par l'opticien.
            </span>
          <td>
        </tr>
        <tr>
          <td>
            <img src="${cdn("icons/attestation_honneur_picto.svg")}">
          </td>
          <td>
            <span class="modal-content">
            <b>
            J'atteste sur l'honneur que je respecte les conditions énoncées ci-dessus pour une exonération des frais,
            et dans le cas contraire je m'expose à des poursuites.
            </b>
            </span>
          </td>
        </tr>
        <table>`,
    cardRefused: "Paiement refusé",
    errorPaymentModalTitle:
      "Votre carte de paiment a été refusée par notre prestataire de paiement. Merci de vous assurer que :",
    errorPaymentModalChangeCard: "Changer de carte",
    errorPaymentModalRetry: "Réessayer",
    errorPaymentModal_html: `
    <table class="table-modal small">
        <tr>
          <td>
            <img src="${cdn("icons/picto_paiement.svg")}">
          </td>
          <td>
            <span class="modal-content">
              Vous avez des <b>fonds suffisants sur votre compte bancaire</b>
            </span>
          <td>
        </tr>
        <tr>
        <td>
          <img src="${cdn("icons/picto_internet.svg")}">
        </td>
        <td>
          <span class="modal-content">
            Vous avez <b>activé le paiement sur internet</b>
          </span>
        <td>
      </tr>
      <tr>
        <td>
          <img src="${cdn("icons/picto_3ds.svg")}">
        </td>
        <td>
          <span class="modal-content">
          Vous avez <b>activé le 3DSecure</b> le cas échéant (double authentification des paiements en ligne)
          </span>
        <td>
      </tr>
      <tr>
        <td>
          <img src="${cdn("icons/picto_carte_valide.svg")}">
        </td>
        <td>
          <span class="modal-content">
          <b>Votre carte est valide</b> pour le paiement
          </span>
        <td>
      </tr>
    <table>
    <div class="center" style="margin-top:20px">
      <b class="error">En cas de besoin, merci de contacter votre banque pour confirmation</b>
    </div>
    `,
    accountCreated: "Votre compte a bien été créé",
    accountError: "Votre compte n'a pas pu être créé",
    addARelative: "Ajouter un proche",
    addCreditCard: "Ajouter la carte",
    addPaimentMethod: "Ajouter une carte de crédit",
    backToPaymentMethod: "Revenir aux moyens de paiement",
    addPhoneNumber: "L'ajouter",
    addRelative: "Ajouter le proche",
    agreement: "Consentement",
    agreementInvalid: "Vous devez accepter les CGUs",
    alreadyAcount: "J'ai déjà un compte patient Medadom",
    anamneseAllergiesQuestionSubtitle: "(Pollen, médicamenteuse, ...)",
    anamneseAllergiesQuestion: "Avez-vous des allergies connues ?",
    anamneseAllergiesQuestion_relative_male:
      "Votre proche a-t-il des allergies connues ?",
    anamneseAllergiesQuestion_relative_female:
      "Votre proche a-t-elle des allergies connues ?",
    anamneseAllergiesQuestionDetails: "Quelle(s) allergie(s) ?",
    anamneseDrugsQuestion: "Prenez-vous régulièrement des médicaments ?",
    anamneseDrugsQuestion_relative_male:
      "Votre proche prend-il régulièrement des médicaments ?",
    anamneseDrugsQuestion_relative_female:
      "Votre proche prend-elle régulièrement des médicaments ?",
    anamneseIllnessQuestionSubtitle: "(Diabète, hypertension, ...)",
    anamneseIllnessQuestion: "Avez-vous déjà eu des maladies chroniques ?",
    anamneseIllnessQuestion_relative_male:
      "Votre proche a-t-il déjà eu des maladies chroniques ?",
    anamneseIllnessQuestion_relative_female:
      "Votre proche a-t-elle déjà eu des maladies chroniques ?",
    anamneseIllnessQuestionDetails: "Quelle(s) maladie(s) ?",
    anamneseOperationsQuestion: "Avez-vous déjà été opéré(e) ?",
    anamneseOperationsQuestion_relative_male:
      "Votre proche a-t-il déjà été opéré ?",
    anamneseOperationsQuestion_relative_female:
      "Votre proche a-t-elle déjà été opérée ?",
    anamnesePregnantQuestion: "Êtes-vous enceinte ?",
    anamnesePregnantQuestion_relative_female:
      "Votre proche est-elle enceinte ?",
    antecedents: "Avez-vous des antécédents ophtalmologiques ?",
    apr: "Avril",
    askAgain: "Relancer la demande",
    ask_for_phone_re_enter:
      "Merci de rentrer de nouveau le téléphone du patient",
    ask_for_voiceCall: "Recevoir le code par appel téléphonique",
    aug: "Août",
    averageConsultationTime: "Durée moyenne des examens",
    backToHome: "Retour à l'accueil",
    birthdate: "Date de naissance",
    birthdatePlaceholder: "JJ / MM / AAAA",
    byPassEmail: "Je n'ai pas d'e-mail",
    byPassNir: "Je n'ai pas de carte vitale",
    enterRelativeNir:
      "Saisissez le numéro de carte vitale du bénéficiaire des droits",
    byPassNir_relative: "Le proche n’a pas de carte vitale",
    noBill: "Vous ne serez pas facturé.",
    doctorCancelCall:
      "Nous sommes navrés, le médecin a annulé la téléconsultation",
    callCancelled: "Annulée",
    callCannotBeCancelled: "Nous n'avons pas pu annuler la consultation",
    callExpired: "Expirée",
    callHasBeenCancelled: "La téléconsultation a été annulée",
    callMeVerify: "Recevoir un appel pour le code",
    call_ophtalmologist: "téléconsultation avec l'ophtalmologue",
    call_ortho: "téléconsultation avec l'orthoptiste",
    call_page: "teleconsultation",
    cancel: "Annuler",
    cancelPath: "Annuler le parcours",
    cancelAndDisconnect: "Annuler et se déconnecter",
    cancelCall: "Annuler la consultation",
    cancelTLC: "Vous allez annuler la demande de téléconsultation",
    cancer: "cancer",
    cannotLaunchTlc: "Impossible de lancer la consultation",
    cataract: "Cataracte",
    cguAgreement:
      "J'ai lu et accepte <b>les conditions générales d'utilisation</b>, ainsi que le traitement de mes données personnelles de santé pour l'utilisation du service",
    change_phone: "Changer de numéro",
    childLessThanSixthen: "les patients de moins de 16 ans",
    choseYourCountry: "Veuillez indiquer le pays",
    civility: "Civilité",
    clickMeToVanish: "puis cliquez-moi pour disparaitre !",
    connexionLoading: "Connexion en cours",
    continueAnyway: "continuer quand même",
    comeBackToLogin: "Revenir à la connexion",
    comeBackToSurvey: "Revenir au parcours",
    confirm: "Confirmer",
    medicalcenter: "Centre de santé",
    chooseYourNationality: "Choisissez votre lieu de naissance",
    confirmHangupCall:
      "Êtes-vous sûr(e) de vouloir raccrocher la téléconsultation ?",
    confirmCancelCall: "Êtes-vous sûr(e) de vouloir annuler la demande ?",
    connectedAs: "Connecté en tant que ",
    connexionSuccess: "Connexion réussie, vous pouvez continuer.",
    connexionFail:
      "Impossible de communiquer avec les machines d'examen de vue.",
    opticianRestartEngine:
      "Opticien, veuillez vérifier que les machines ont été allumées correctement et dans le bon ordre, puis relancer.",
    contactOptician: "Contactez l'opticien",
    diagnosticFailedTitle: "Communication avec les machines d'examen de vue impossible",
    toCheckmachines: "pour vérifier et redémarrer les machines",
    consultationScheduledAt:
      "a confirmé la téléconsultation pour ce créneau. Il peut avoir du retard, merci de patienter.",
    contact_secretary:
      "Veuillez contacter le secrétériat \r\n afin de créer votre compte",
    continue: "Continuer",
    allTheSame: "quand même",
    IUnderstandAndContinue: "Je continue quand même",
    createCall: "Lancer la demande",
    creating_call: "Demande en cours",
    credit: "© 2021 MEDADOM Tous droits réservés",
    creditCard: "carte bancaire",
    currentlyReadingNir: "Lecture de la carte en cours",
    dateInvalid: "La date de naissance est invalide",
    dec: "Décembre",
    defaultErrorForm: "Une erreur s'est glissée dans le formulaire",
    defaultReplyWhichWho: "L'un de nos médecins disponible",
    deleteCard: "Supprimer la carte",
    demo: "la démonstration des machines",
    demo_page: "demonstration-outils",
    diabetes: "diabète",
    diagnostic: "Vérifications techniques",
    diagnosticSubtitle:
      "Nous vérifions les connexions entre la borne et le matériel d'examen visuel",
    digestiveDisease: "maladie digestive",
    doYouHaveAnAccount: "Avez-vous un compte MEDADOM ?",
    anamneseChoiceOfDoctor: "Que souhaitez-vous faire ?",
    anamneseConsultNow: "Consulter <b>dès maintenant</b>",
    consultNow: "Consulter dès maintenant",
    now: "maintenant",
    availability: "Disponibilité",
    thisDoctorIsNotAvailable_html:
      "Ce médecin est indisponible, mais un médecin du <b>CDS Mediksanté (Paris)</b> peut vous <b>prendre en charge immédiatement</b>",
    IConsultThisDoctor: "Je veux consulter ce médecin",
    ifDoctorDoNotExist:
      "Si le médecin que vous cherchez ne consulte pas encore sur MEDADOM, vous pourrez consulter un autre médecin disponible",
    requestDoctorPlaceholder: "Entrez le nom du médecin",
    anamneseChooseADoctor: "J'ai RDV avec un <b>médecin spécifique</b>",
    chooseADoctor: "J'ai rendez-vous avec un médecin spécifique",
    anamneseChooseADoctor_relative_female:
      "Ma proche a RDV avec un <b>médecin spécifique</b>",
    anamneseChooseADoctor_relative_male:
      "Mon proche a RDV avec un <b>médecin spécifique</b>",
    anamneseDoctorAvailableQuestion: "Quelle est votre situation ?",
    nirIsExpiredTitle: "Votre Carte Vitale n'est pas à jour !",
    nirIsExpiredContent: `<span>
      Cela peut avoir une incidence sur le remboursement de votre téléconsultation,
    </span>
        <br />
    <b>
      mettez là à jour auprès de votre pharmacien
    </b>`,
    anamneseDoctorAvailableQuestion_relative_male:
      "Quelle est la situation de votre proche ?",
    anamneseDoctorAvailableQuestion_relative_female:
      "Quelle est la situation de votre proche ?",
    doNotHaveDoctor: "<b>Je n'ai pas</b> de médecin traitant",
    doctorNotAvailable: "Mon médecin traitant <b>n'est pas disponible</b>",
    doctorNotAvailable_relative_female:
      "Le médecin traitant de ma proche <b>n'est pas disponible</b>",
    doctorNotAvailable_relative_male:
      "Le médecin traitant de mon proche <b>n'est pas disponible</b>",
    doctorNotInList: "Mon médecin n'est pas dans la liste",
    doctorAwaiting: "Votre médecin est prêt ! Votre consultation va commencer.",
    documentAvailableInfo:
      "Retrouvez vos documents médicaux dans votre espace Medadom",
    drugAllergy: "allergie aux médicaments",
    doctorType: (type: DoctorType) => {
      switch (type) {
        case DoctorType.ORTHOPTIST:
          return "orthoptiste"
        case DoctorType.OPHTALMOLOGIST:
          return "ophtalmologue"
        default:
          return "docteur"
      }
    },
    foodAllergy: "allergie alimentaire",
    email: "e-mail",
    notSuitableTLC: "Téléconsultation non adaptée",
    IHaveRelativeConsent: "J'ai le consentement",
    cguAndPrivacyTitle: "Conditions générales et données personnelles",
    enterRelativeInformations: "Ajout d'un proche",
    enter_lastname: "Veuillez saisir votre nom de famille",
    InputYourOldPassword: "Veuillez entrer votre ancien mot de passe",
    enter_password:
      "Définissez un mot de passe, \n\r pour la création de votre compte Medadom",
    enter_phone: "Rentrez votre numéro de téléphone",
    notSuitableTLCContent:
      "Les enfants de <b>moins de 3 ans</b> nécessitent un examen clinique.<br/>La téléconsultation n'est pas adaptée à cette prise en charge et  n'est donc <b>pas possible</b> dans ce cas.",
    IGotIt: "J'ai compris",
    errorCallState:
      "Une erreur est survenu lors du call, \n\r Veuillez contacter notre support",
    errorLogin: "Une erreur s'est glissée dans le formulaire",
    errorOccuredWhenSendingCode:
      "Une erreur est survenue lors de l'envoi du code",
    existingOrderError: "La téléconsultation existe déjà",
    expiredSession: "La session est expirée. Nous allons vous déconnecter.",
    feb: "Février",
    female: "Femme",
    finalePageTitle: "Merci de votre visite !",
    firstConsultation: "1ère consultation lunettes",
    add_pseudo_firstname: "AJOUTER UN PRENOM UTILISÉ",
    add_pseudo_lastname: "AJOUTER UN NOM UTILISÉ",
    pseudo_lastname: "Nom utilisé",
    pseudo_firstname: "Prénom utilisé",
    firstname: "Prénom utilisé au quotidien",
    first_birth_firstname: "1er Prénom de naissance",
    first_firstname: "prénom",
    first: "1er",
    of_birth: " de naissance",
    forMe: "Pour moi",
    forRelative: "Pour un proche",
    forgottenPassword: "J'ai oublié mon mot de passe",
    gender: "Genre",
    some_information_comes_from: "Certaines informations de votre profil ont été récupérées via votre",
    carte_vitale: "carte vitale",
    and_are_not: "et ne sont",
    modifiable: "pas modifiables.",
    identity_data: "Données d’identité",
    you_can_add: "Vous pouvez ajouter ",
    a_pseudo_last_name_different: "un nom utilisé au quotidien, différent",
    a_pseudo_first_name_different: "un prénom utilisé au quotidien, différent",
    of_your_birth_last_name: "de votre nom de naissance.",
    of_your_birth_first_name: "de votre prénom de naissance.",
    not_informed: "Non renseignés",
    not_informed_singular: "Non renseigné",
    glassesRenewal: "Renouvellement des lunettes",
    glaucoma: "Glaucome",
    errorDate_shouldBeOlderThan: (minAge?: number) =>
      `Vous devez avoir plus de ${minAge || 3} ans.`,
    errorDate_shouldBeYoungerThan: (maxAge?: number) =>
      `La téléconsultation n'est pas possible après ${maxAge || 110} ans`,
    invalideDate: "Date de naissance incomplète ou erronée",
    DD: "JJ",
    MM: "MM",
    YYYY: "AAAA",
    goBackToProfile: "Retour au profil",
    helper: "L'équipe MEDADOM vous informe :",
    doctorIsWritingYourDocs:
      "Le médecin est en train de rédiger vos documents médicaux.",
    findDocumentsInYourProfile:
      "Retrouvez à tout moment vos documents médicaux depuis votre espace MEDADOM.",
    helperRegardingGenderRelative:
      "Vérifiez bien le genre de la personne que vous ajoutez !",
    hangup: "Raccrocher",
    hangupTitleWarning: "Souhaitez-vous raccrocher ?",
    hangup_subtitle: "Veuillez patienter quelques instants...",
    hangup_title: "`Rédaction des documents par le médecin`",
    heartDisease: "maladie cardiaque",
    hypertension: "hypertension",
    IRefuseConsent: "Annuler",
    IAcceptConsent: "J'accepte",
    consentTitleModal: "CGU",
    consentContentModal: `J'ai lu et accepte <a>les conditions générales d'utilisation</a>, ainsi que le traitement de mes données personnelles de santé pour l'utilisation du service`,
    inProgress: "En cours...",
    inactivity: "Vous allez être déconnecté pour inactivité",
    incorrectInput: "Merci d'entrer un champs valide pour",
    infectiousDisease: "maladie infectieuse",
    inform_anamnesis:
      "Le médecin a besoin de certaines informations médicales vous concernant",
    initial_step: "Parcours de votre téléconsultation",
    inform_anamnesis_relative_male:
      "Le médecin a besoin de certaines informations médicales concernant le proche",
    inform_anamnesis_relative_female:
      "Le médecin a besoin de certaines informations médicales concernant la proche",
    inputYour: "Entrez votre ",
    inputYourBirthCountry: "Entrez votre pays de naissance",
    inputYourBirthCity: "Entrez votre le lieu de naissance",
    inputYourRelativeBirthCity: "Entrez le lieu de naissance du proche",
    inputYourRelativeBirthCountry: "Entrez le pays de naissance du proche",
    unknwonBirthPlace: "Passez à l'étape suivante",
    insertYourNir: "Insérez votre carte vitale",
    createPassword: "Veuillez créer un mot de passe",
    ineligibleReasonInformation:
      "Pour ces motifs, vous devez vous rendre chez votre médecin traitant ou consulter un médecin en présentiel.",
    invalidValidationCode:
      "Une erreur est survenue lors de la validation du code",
    invalid_password: "Mot de passe invalide",
    jan: "Janvier",
    joinCallIn: "Rejoignez l'appel dans",
    joinTheConsultation: "Rejoindre la consultation",
    jul: "Juillet",
    jun: "Juin",
    keratoconus: "Kératocône",
    kidneyDisease: "maladie rénale",
    lastname: "Nom utilisé au quotidien",
    birth_lastname: "Nom de naissance",
    launch_tlc: "Lancer une téléconsultation",
    legalMentionArticleSix:
      "Conformément à l'article 6 du code de la santé publique, vous êtes libre du choix de votre opticien",
    lensesRenewal: "Renouvellement de lunettes et lentilles",
    lessThan: "Moins de",
    lessThanAYear: "Moins d'1 an",
    locationError: "Veuillez renseigner un lieu valide",
    locationInvalid: "Veuillez selectionner un lieu de naissance",
    locationAddress: "Lieu de naissance",
    locationAddressError: "Lieu de naissance invalide",
    login: "Se connecter",
    loginNir: "Valider",
    verifyEmail:
      "Pour confirmer le changement, entrez le code reçu par email à l'adresse suivante",
    verifyPhone:
      "Pour confirmer le changement, entrez le code reçu au numéro suivant",
    loginTitle: "J'ai déjà un compte patient",
    logoutTitle: "Souhaitez-vous être déconnecté ?",
    logout: "Se déconnecter",
    logoutAdmin: "Déconnexion opticien",
    male: "Homme",
    mar: "Mars",
    may: "Mai",
    me: "moi",
    mismatchingErrorForm: "Les identifiants ne correspondent pas",
    mustContainLessThanHundredCharacters:
      "Le champ doit contenir moins de 100 caractères",
    cantContainSpaceAsFirstCharacters:
      "Le premier caractère ne peut être un espace ou tiret",
    cantContainTwoConsecutiveSpace:
      "Le champ ne peut contenir deux espaces consécutifs",
    cantContainTwoConsecutiveApostrophe:
      "Le champ ne peut contenir deux apostrophes consécutifs",
    cantContainTwoConsecutiveHyphen:
      "Le champ ne peut contenir que deux tirets consécutifs",
    cantContainHyphenFollowedByApostrophe:
      "Le champ ne peut contenir un tiret suivi d'un apostrophe",
    cantContainHyphenFollowedBySpace:
      "Le champ ne peut contenir un tiret suivi d'un espace",
    cantFinishWithHyphenOrApostrophe:
      "Le champ ne peut pas finir par un tiret ou un apostrophe",
    shouldStartWithAlphaOrApostrophe:
      "Le champ doit commencer par un caractère ou un apostrophe",
    shouldContainAtLeastOneAlphaCharacter:
      "Le champ doit contenir au moins un caractère alphanumérique",
    shouldNotContainSlash: "Le champ ne peut contenir de slash",
    modify: "Modifier",
    add: "Ajouter",
    ofTheRelative: "du proche",
    modifyRelative: "Modifier mon proche",
    of: "de",
    label_input_the_male: "Entrez le ",
    inputThe: (name: string) => {
      if (["birthdate"].includes(name)) return "Entrez la " + languages[name]
      else return "Entrez le " + languages[name]
    },

    modifyThe: (name: string) => {
      return "Modifiez le " + languages[name]
    },
    modifyYour: (name: string) => {
      return "Modifiez votre " + languages[name]
    },

    addA: (name: string) => {
      return "Ajouter un " + languages[name]
    },

    nirWellKnown: "Le numéro de carte vitale est bien enregistré",
    exemptionEnded:
      "Fin de la mesure gouvernementale Covid-19 sur le tiers payant intégral. À compter du 1er octobre 2022, le patient devra à nouveau s’acquitter de la part complémentaire (remboursée par la mutuelle)",
    tlcExemptedDate: "Au 1er octobre 2022",
    tlcExemptedContent:
      "la prise en charge règlementaire de la Sécurité Sociale redeviendra de 70%, et le reste à charge de 30% (remboursable par votre mutuelle). Le contexte sanitaire actuel exonère d'avance de frais en téléconsultation jusqu'au 30/09/2022.",
    nextMeansYouAcceptCGU:
      "En poursuivant la demande, je confirme avoir pris connaissance des CGU",
    putHeadPhones: "Si la borne en est équipée, mettez le casque audio",
    moreThanAYear: "Plus d'1 an",
    needHelp: "Besoin d'aide ?",
    next: "Suivant",
    nir: "Numéro de carte vitale",
    nirInvalid: "Le numéro de carte vitale n'est pas valide",
    nirLengthError: "Le numéro de carte vitale doit contenir 15 chiffres",
    nirManualInput: "Passer l'étape",
    nirReaderError: "Nous n'avons pas pu récupérer les informations",
    codeSpacer: " ",
    codeAllowedChar: "9",
    nirSpacer: " / ",
    nirMaskChar: "_",
    nirAllowedChar: "*",
    no: "NON",
    doctorIsPrescribing: "Rédaction des documents par le médecin",
    youWillBeInformedBySMS: "En cas d'avance, vous serez prévenu(e) par sms",
    youWillBeInformedBySMSFifo:
      "Vous serez prévenu(e) par SMS avant le début de votre téléconsultation",
    get_ready_for_next_step: (content: string) =>
      `Prochaine étape : \n\r ${content} `,
    noAppointment: "Je n'ai pas de rendez-vous",
    noAppointment_relative_male: "Mon proche n'a pas de rendez-vous",
    noAppointment_relative_female: "Mon proche n'a pas de rendez-vous",
    noDocument: "le docteur n'a délivré aucune ordonnance",
    IwantToAddAnEmail: "JE RENSEIGNE MON E-MAIL",
    noEmailContentModal: `<div>
      <div class="center" style="margin-bottom:80px">
        <img  src="${cdn("icons/mail_picto.svg")}" alt="">
      </div>
      <table class="table-modal">
        <tr>
          <td></td>
          <td><b>En ne renseignant pas votre adresse email :</b></td></tr>
        <tr>
          <td><img height="100" src="${cdn(
      "icons/supprimer.svg"
    )}" alt=""></td>
          <td>Vous ne pourrez recevoir d'informations sur les documents médicaux liés à votre consultation.</td>
        </tr>
        <tr>
          <td><img height="100" src="${cdn(
      "icons/supprimer.svg"
    )}" alt=""></td>
          <td>Nos équipes médicales et administratives ne pourront pas vous contacter en cas de besoin.</td>
        </tr>
      </table>
    </div>`,
    noEmailTitleModal: "Adresse e-mail obligatoire",
    noRelativesYet: "Il n'y a actuellement pas de proche",
    noNirTitleModal: "Attention",
    noNirContentModal:
      "Vous devrez régler la totalité de la téléconsultation médicale.",
    noHistory: "Pas d'historique",
    notAdapted: "La téléconsultation n'est pas adaptée pour :",
    notBornInFrance: "Je ne suis pas né(e) en France ?",
    bornInFrance: "Je suis né(e) en France ?",
    relativeBornInFrance: "Le proche est  né(e) en France ?",
    relativeNotBornInFrance: "Le proche n'est pas né(e) en France ?",
    birth_location: "Lieu de naissance",
    french: "France",
    foreign: "Étranger",
    unknown: "Inconnu",
    nov: "Novembre",
    oct: "Octobre",
    optional: "(optionnel)",
    orderForWhichDoctor: "Avec quel médecin avez-vous rendez-vous ?",
    orderForWhichDoctor_relative_male:
      "Avec quel médecin votre proche a-t-il rendez-vous ?",
    orderForWhichDoctor_relative_female:
      "Avec quel médecin votre proche a-t-elle rendez-vous ?",
    orderForWhichHour: "À Quelle heure avez-vous rendez-vous ?",
    orderForWhichHour_relative_male:
      "À Quelle heure votre proche a-t-il rendez-vous ?",
    orderForWhichHour_relative_female:
      "À Quelle heure votre proche a-t-elle rendez-vous ?",
    orderForWho: "Pour quelle(s) personne(s) est la consultation ?",
    otherAllergy: "autre",
    otherDisease: "autre",
    otherOphtalmologicDisease: "Autre",
    page_admin: "administrateur",
    page_check_lastname: "validation-du-nom",
    page_check_password: "validation-du-mot-de-passe",
    page_check_phone: "validation-du-telephone",
    page_home: "maison",
    page_insert_relative: "creation-de-proche",
    page_lobby: "choisir-sa-connexion",
    page_login: "connexion",
    page_nir: "carte-vitale",
    page_not_authorized: "401",
    page_not_found: "404",
    page_order: "questions",
    page_register: "inscription",
    page_register_personal_data: "saisie-des-donnees-personnelles",
    page_reset_password: "reinitialisation-mot-de-passe",
    page_verify: "verification",
    page_verify_phone: "verification-du-mobile",
    page_welcome: "bienvenue",
    panel_for_secretary:
      "Si vous êtes la secrétaire, veuillez saisir le mot de passe administrateur",
    password: "Mot de passe",
    passwordMustBeEightLength:
      "Le mot de passe doit contenir au moins 8 caractères",
    password_validation_char: "8 caractères",
    password_validation_lower: "une lettre minuscule",
    password_validation_number: "un chiffre",
    password_validation_title: "Votre mot de passe doit contenir au moins:",
    password_validation_upper: "une lettre majuscule",
    patientPutError: "Nous n'avons pas pu mettre à jour votre profil",
    patientPutNirError: "Votre numéro de sécurité sociale n'est pas valide",
    patientPutNirSuccess: "Votre numéro de sécurité sociale est valide",
    payloadErrorForm: "Une erreur s'est glissée dans la requête",
    peoplesOlderThanSeventyFive: "les patients de plus de 75 ans",
    phone: "Téléphone portable",
    phoneExistingErrorForm:
      "Le numéro de téléphone est déjà utilisé pour un autre compte",
    phoneInvalid: "Le téléphone n'est pas valide",
    phoneNotFound: "Le numéro de téléphone n'existe pas dans notre base",
    playerStopped: "Le Lecteur s'est arrêté.",
    pollenAllergy: "allergie au pollen",
    postCallTitle: "`Téléconsultation terminée avec succès`",
    post_call_page: "transition",
    previous: "Retour",
    print: "Imprimer",
    rePrint: "Ré-Imprimer",
    beSureDocumentsArePrinted:
      "Soyez certain que vos documents ont bien été imprimés ou partagés avec l'opticien, avant la déconnexion",
    printDocument: "Imprimer les documents",
    profile_myHistory: "Documents",
    profile_myInformations: "Informations",
    profile_myPayments: "Moyens de paiement",
    profile_mySettings: "Paramètres",
    provideYourMail:
      "merci de saisir votre email pour le suivi de votre téléconsultation",
    questionForWho: "Pour qui est la consultation ?",
    questionWhichRelative: "Pour quel proche ?",
    questionWhichTime: "A quelle heure avez-vous rendez-vous ?",
    questionWhichWho: "Avec quel médecin avez-vous rendez-vous ?",
    question_step: "les questions pré-consultation",
    reset: "Vider le champ",
    re_enter_phone: "Re-saissir mon numéro de téléphone",
    readerInitialize: "Initialisation...",
    readerSocketGenericError:
      "Une erreur est survenue sur le lecteur de carte, \r\n Veuillez le saisir manuellement",
    readerSocketReadError:
      "Une erreur est survenue lors de la lecture de votre carte",
    readerStopped: "Le lecteur s'est arrêté",
    reading: "\tLecture en cours...",
    reason: "Quel est le motif de votre visite ?",
    reconnectAfterQuestion:
      "Vous allez être reconnecté à votre consultation suite à la saisie des questions pré-consultation",
    redirectAfterQuestion:
      "Vous allez revenir à la dernière étape (RDV ophtalmologue) suite à la saisie des questions pré-consultation",
    register: "S'inscrire",
    registerStep_complementary: "Identifiants",
    registerStep_creation: "Création du compte",
    registerStep_nir: "Carte vitale",
    registerStep_personal: "Informations",
    registerStep_verification: "Téléphone",
    registerTitle: "Je m'inscris",
    register_creation_inProgress: "Création du compte en cours",
    register_validation_inProgress: "Validation du compte en cours...",
    relative: "proche",
    relativesAddedError: "Vos proches n'ont pas pu être ajouté à votre compte",
    relativesAddedSuccess: "Vos proches ont bien été ajoutés",
    relaunchDiagnostic: "Relancer",
    relativeUseAnother: (name: string) => `Le proche utilise un autre ${name}`,
    relaunchPlayer: "Relancer le lecteur",
    removeYourNir: "Veuillez retirer votre carte",
    resendCodeInfo: "En cliquant sur le bouton, un nouvel SMS vous sera envoyé",
    resend_code: "Renvoyer le code par sms",
    resetPasswordRequestIsMadeTitle: (transport: 'sms' | 'email') =>
      `Si un compte est associé à ce numéro de téléphone, un ${transport} vous a été envoyé`,
    resetPasswordTitle:
      "Renseignez votre numéro de téléphone portable pour la réinitialisation de votre mot de passe",
    respiratoryDisease: "maladie respiratoire",
    restartDiagnostic: "relancer",
    referrerIsAHealthProfessional:
      "Les documents seront envoyés au référent du dispositif",
    scheduledConsultation: "Téléconsultation programmée",
    subscribePlaceholder:
      "Autoriser MEDADOM à vous envoyer des communications par e-mail",
    doctorBusy: "Tous nos docteurs, sont occupés. Nous sommes navrés.",
    selectDayBirthdate: "Jour",
    selectMonthBirthdate: "Mois",
    selectYearBirthdate: "Année",
    sendCode: "Envoyer le code",
    sendDocumentsToTheReferrer: "Envoyer à l'opticien",
    sent: "Envoyé !",
    sep: "Septembre",
    smsNotReceived: "Je n'ai pas reçu le sms",
    step_page: "etape",
    strabismus: "Strabisme",
    tlcMade: "Téléconsultations réalisées",
    thanks: "Merci",
    tlcOf: "Téléconsultation du",
    thanksForYourAnswers:
      "Merci d’avoir répondu aux questions. Les données renseignées restent confidentielles.",
    thirtyMinutes: "30 min",
    tooltipResetPassword:
      "Un SMS vous sera envoyé avec les informations pour vous connecter",
    tooManyCallsForACustomer:
      "Vous avez déjà effectué une téléconsultation aujourd'hui",
    touch_screen: "Touchez l'écran pour démarrer",
    tryAgain: "Relancer le lecteur",
    useCard: "Lancer la téléconsultation",
    useAnother: (name: string) => `J'utilise au quotidien un autre ${name}`,
    majorityRequired: "Majorité légale requise",
    minAgeMessage: (minAge: number) => `L'âge doit être d'au moins ${minAge} ans.`,
    majorityRequiredInfo: "Le titulaire du compte doit être âgé d’au moins 16 ans au moment de son inscription.",
    majorityRequiredInfoFirstChunk: "Le titulaire du compte doit être âgé ",
    majorityRequiredInfoSecondChunk: "d’au moins 16 ans ",
    majorityRequiredInfoLastChunk: "au moment de son inscription.",
    majorityRequiredAddRelative: "Vous pourrez ajouter un proche mineur au moment de la téléconsultation.",
    unknownBirthLocation: "Lieu de naissance inconnu",
    newConsultation: "Relancer la consultation",
    validate: "Valider",
    verifiedPhoneError: "Le code de vérification est erroné",
    verifiedPhoneSuccess: "Votre téléphone a bien été validé",
    verifyCallSuccess: "Un SMS vous a été renvoyé.",
    verifyCallSendSuccess:
      "Vous allez recevoir un appel dans quelques secondes",
    verifyNir: "Vérifiez les numéros avant de passer à l'étape suivante",
    waitingForNirReader: "Recherche du lecteur de carte vitale...",
    answerIn: "La recherche d’une disponibilité peut prendre encore jusqu’à ….",
    tlcExpectedIn: "Téléconsultation prévue dans : ",
    weAreLookingForDoctors:
      "Nous recherchons actuellement un médecin disponible, merci de patienter.",
    weekly: "Cette semaine",
    monthly: "Ce mois-ci",
    yearly: "Cette année",
    total: "Total",
    daily: "Aujourd'hui",
    in: "En",
    welcome: "Bienvenue",
    wichOnes: "Lesquels (sélection multiple possible) ?",
    writeNir: "Inscrivez vos numéros",
    writeVerifyCode: "Entrez le code reçu  au ",
    wrongNirLength: "Veuillez saisir 15 chiffres",
    wrongWay:
      "Il semble que vous ne soyez pas arriver ici en suivant le bon chemin",
    yes: "OUI",
    youHaveCancelledCall: "Vous avez annulé la téléconsultation",
    youNeedToRemoveLensesBeforeConsultation:
      "L’examen médical nécessite un retrait de vos lentilles la veille de la téléconsultation.",
    youWillLaunchATLC: "Vous allez lancer une demande de mise en relation",
    yourDocuments: "Vos Documents",
    yourPharmaAccount: "Votre compte opticien",
    yourWaitingTime: "Votre temps d'attente estimé :",
    // tutorial
    take: "Prenez",
    finish: "Terminer",
    warning: "Attention",
    modalContent_changePatientInformation_html:
      "Si vous souhaitez téléconsulter pour <b>une autre personne</b>, vous<br/> devez <b>ajouter un proche</b> lors de votre prochain parcours de<br/>téléconsultation <b>et non modifier les données</b>",
    modifyDatas: "Modifier les données",
    datas_and_consent_title: "Données et consentement",
    datas_and_consent_point_1:
      "Je confirme mon libre choix à la téléconsultation et qu'elle ne m'est imposée par aucun contexte particulier",
    datas_and_consent_point_2:
      "J'accepte la collecte de mes données personnelles nécessaires :",
    datas_and_consent_point_2_checkbox_1:
      "à mon parcours de soins (gestion du dossier administratif, médical, facturation, sms)",
    datas_and_consent_point_2_checkbox_2:
      "à l'établissement de statistiques anonymes et amélioration du produit dans le respect de la réglementation applicable",
    datas_and_consent_point_3:
      "Medadom et ses sous-traitants protègent vos données de santé. Pour plus d'informations ainsi que les éventuels liens d'intérêts, voir",
    datas_and_consent_point_4: "Les mises en relation s'effectuent dans le cadre d'un protocole de coopération entre professionnels de santé (orthoptiste et ophtalmologiste)",
    datas_and_content_point_3_ref: " la politique de confidentialité",
    IConfirmAndContinue: "Je confirme et continue",
    conflictError: "Déjà existant",
    addCarer: "Ajouter un accompagnant",
    addingCarer: "Ajout d'un accompagnant",
    addProfessional: "Ajouter un professionnel",
    proOrReferent: "Un professionnel de santé ou référent",
    aRelative: "Un proche",
    asCarer: "En tant qu'accompagnant,",
    iConsentTo: "je m'engage à",
    keepInformationConfidential: "garder confidentielles les informations échangées",
    duringTLC: 'lors de la téléconsultation',
    iCommit: "Je m'engage",
    insertAdminEmail:
      "Par mesure de sécurité, merci de demander à l'administrateur du dispositif de téléconsultation d'indiquer son email",
    IsaPro: "Est-ce un professionnel de santé ?",
    isCarerInTheList: "L'accompagnant est-il dans la liste ?",
    exemplePro: "(pharmacien, infirmier, ...)",
    whichRelativeIsCaringYou: "Quel proche vous accompagne ?",
    whichProfessionalIsCaringYou: "Quel professionnel vous accompagne ?",
    whichProfessionalIsCaringYou_relative:
      "Quel professionnel accompagne votre proche ?",
    whatIsRelationship: "Quel est le lien de parenté de l'accompagnant ?",
    confirmRemoveCarer: "Vous allez supprimer l'accompagnant suivant :",
    incorrectFieldAdminEmail: "Le champ est incorrect",
    forMoreSecurity: "Pour plus de sécurité",
    insertFirstnameCarer: "Entrez le prénom de l'accompagnant",
    insertLastnameCarer: "Entrez le nom de famille de l'accompagnant",
    insertPhoneCarer: "Entrez le numéro de téléphone de l'accompagnant",
    insertRpps: "Entrez le RPPS/ADELI du professionnel de santé accompagnant",
    addPro: `Souhaitez-vous ajouter ce professionnel ?`,
    healthPro: "Professionnel de santé",
    delete: "Supprimer",
    otherCarer: "Autre accompagnant",
    noCarer: "Aucun accompagnant",
    ImCaredForThisTlc_relative:
      "Mon proche n'est pas autonome et est accompagné(e) pour cette consultation",
    ImCaredForThisTlc:
      "Je ne suis pas autonome et je suis accompagné(e) pour cette consultation",
    IConfirmmyConsentDataChoice:
      "Je confirme mon libre choix à la mise en relation dans le cadre d'un protocole de coopération entre professionnels de santé et accepte la collecte de mes données personnelles.",
    required: "Valeur incorrecte",
    whoIsCaringYouForTheTLC:
      "Qui vous accompagne pour cette téléconsultation ?",
    whoIsCaringYouForTheTLC_relative:
      "Qui accompagne votre proche pour cette téléconsultation ?",
    whoIsCaringYou: "Quelle personne vous accompagne ? ",
    whoIsCaringYou_relative: "Quelle personne accompagne votre proche ?",
    rppsLengthError: "Le RPPS/ADELI doit contenir un maximum de 11 chiffres",
    passwordHasNotBeenChangedSince_html: (days: number) => {
      if (days <= 0)
        return `<div>Votre mot de passe a <b>expiré</b>, cela fait plus de ${Math.abs(days)} ${Math.abs(days) <= 1 ? "jour" : "jours"} que vous ne l'avez pas modifié. Pour plus de <b>sécurité</b>, veuillez le modifier <b>dès à présent</b>.</div>`
      if (days === 1)
        return `<div>Votre mot de passe <b>expire demain.</b> Pour des raisons de <b>sécurité</b>, nous vous encourageons à le renouveler dès à présent.</div>`
      return `<div>Votre mot de passe <b>expire dans ${days} jours.</b> Pour des raisons de <b>sécurité</b>, nous vous encourageons à le renouveler dès à présent.</div>`
    },
    later: "Plus tard",
    modifyPassword: "Modifier mon mot de passe",
    ifMailIsMissing: "En l'absence d'email associé au compte, la réinitialisation se fera par SMS",
    choosePasswordRenewMethod: "Choisissez une méthode pour recevoir le lien permettant la réinitialisation de votre mot de passe",
    sms: "SMS",
    Email: "E-mail",
    additionalFeeCanBeCharged: "Des frais additionnels d'accompagnement peuvent être facturés par l'opticien.",
    tooYoungOrOld: <>Nous sommes navrés mais la téléconsultation n'est <span style={{ color: "#d04c4d" }}>pas adaptée</span> pour :</>,
    tooYoungSubtitle: <>les patients de <span style={{ color: "#d04c4d" }}>moins de 16 ans</span></>,
    tooOldSubtitle: <>les patients de <span style={{ color: "#d04c4d" }}>plus de 75 ans</span></>,
  },
  en: {},
} as GlobalStrings<{
  [x: string]: any
}>)
languages.setLanguage("fr")
