import { illness } from "./medical_questions"
import {
  optic_illness,
  reason,
  tutorial,
} from "./optic_questions"
import {
  call_ophta_on_going,
  call_ortho_on_going,
  consent,
  consultation_time,
  diagnostic,
  exemption,
  inform_anamnesis,
  patient,
  payment,
  remove_lenses,
  timeline,
} from "./questions"

// Les étapes dans l'ordre
export const OPTIC_STEPS = [
  timeline("initialize"),
  diagnostic,
  patient,
  consultation_time,
  remove_lenses,
  exemption,
  timeline("anamnesis"),
  inform_anamnesis,
  reason,
  illness,
  optic_illness,
  timeline("tutorial-video"),
  tutorial,
  // consent,
  // This timelinepart is contained in payment
  // timeline("orthoptist"),
  payment,
  call_ortho_on_going,
  // This timelinepart is contained in payment
  // timeline("ophtalmologist"),
  payment,
  call_ophta_on_going,
]
